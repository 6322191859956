import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './banner1.css'

const Banner1 = (props) => {
  return (
    <div className="banner1-container1 thq-section-padding">
      <div className="banner1-max-width thq-section-max-width">
        <div className="banner1-container2">
          <h2 className="banner1-title thq-heading-2">
            {props.heading1 ?? (
              <Fragment>
                <span className="banner1-text7">Spank That Bitch</span>
              </Fragment>
            )}
          </h2>
          <p className="banner1-text1 thq-body-large">
            <span className="banner1-text2">
              Do you even
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="banner1-text3">$SPANK</span>
            <span className="banner1-text4">, bro?</span>
          </p>
          <div className="banner1-container3">
            <a
              href="https://t.me/welcomespank"
              target="_blank"
              rel="noreferrer noopener"
              className="banner1-link1"
            >
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="banner1-image1"
              />
            </a>
            <a
              href="https://x.com/spankthatbitch"
              target="_blank"
              rel="noreferrer noopener"
              className="banner1-link2"
            >
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                className="banner1-image2"
              />
            </a>
            <a
              href="https://dexscreener.com/sui/0x7eb3600af63629f2d117985d6d3cd3b074df3d315f5fb0eebe3ae2b33c74583e"
              target="_blank"
              rel="noreferrer noopener"
              className="banner1-link3"
            >
              <img
                alt={props.imageAlt2}
                src={props.imageSrc2}
                className="banner1-image3"
              />
            </a>
            <a
              href="https://dex.bluemove.net/swap/0x2::sui::SUI/0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK"
              target="_blank"
              rel="noreferrer noopener"
              className="banner1-link4"
            >
              <img
                alt={props.imageAlt21}
                src={props.imageSrc21}
                className="banner1-image4"
              />
            </a>
          </div>
        </div>
        <h1 className="banner1-text5">
          {props.heading ?? (
            <Fragment>
              <span className="banner1-text6">
                0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK
              </span>
            </Fragment>
          )}
        </h1>
      </div>
    </div>
  )
}

Banner1.defaultProps = {
  heading: undefined,
  heading1: undefined,
  imageSrc1: '/mask%20group%20(11)-200h.png',
  imageSrc2: '/mask%20group%20(10)-200h.png',
  imageAlt: 'image',
  imageSrc21: '/download-200h.png',
  imageSrc: '/mask%20group%20(12)-200h.png',
  imageAlt21: 'image',
  imageAlt1: 'image',
  imageAlt2: 'image',
}

Banner1.propTypes = {
  heading: PropTypes.element,
  heading1: PropTypes.element,
  imageSrc1: PropTypes.string,
  imageSrc2: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc21: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt21: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageAlt2: PropTypes.string,
}

export default Banner1
