import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './footer4.css'

const Footer4 = (props) => {
  return (
    <footer
      className={`footer4-footer7 thq-section-padding ${props.rootClassName} `}
    >
      <div className="footer4-max-width thq-section-max-width">
        <div className="footer4-content">
          <div className="footer4-logo1">
            <img
              alt={props.logoAlt}
              src={props.logoSrc}
              className="footer4-logo2"
            />
          </div>
          <div className="footer4-links">
            <a
              href="https://dex.bluemove.net/token/0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK/0x2::sui::SUI"
              target="_blank"
              rel="noreferrer noopener"
              className="footer4-link1 thq-body-small"
            >
              {props.link1 ?? (
                <Fragment>
                  <span className="footer4-text2">About Us</span>
                </Fragment>
              )}
            </a>
            <a
              href="https://dexscreener.com/sui/0x7eb3600af63629f2d117985d6d3cd3b074df3d315f5fb0eebe3ae2b33c74583e"
              target="_blank"
              rel="noreferrer noopener"
              className="footer4-link2 thq-body-small"
            >
              {props.link11 ?? (
                <Fragment>
                  <span className="footer4-text3">About Us</span>
                </Fragment>
              )}
            </a>
            <a
              href="https://t.me/welcomespank"
              target="_blank"
              rel="noreferrer noopener"
              className="footer4-link3 thq-body-small"
            >
              {props.link12 ?? (
                <Fragment>
                  <span className="footer4-text4">About Us</span>
                </Fragment>
              )}
            </a>
          </div>
        </div>
        <div className="footer4-credits">
          <div className="footer4-divider thq-divider-horizontal"></div>
          <div className="footer4-row">
            <div className="footer4-container">
              <span className="footer4-text1 thq-body-small">
                © 2024SpankThatBitch
              </span>
            </div>
            <div className="footer4-footer-links"></div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer4.defaultProps = {
  logoSrc: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  link1: undefined,
  link11: undefined,
  link12: undefined,
  rootClassName: '',
  logoAlt: 'Spank That Bitch Logo',
}

Footer4.propTypes = {
  logoSrc: PropTypes.string,
  link1: PropTypes.element,
  link11: PropTypes.element,
  link12: PropTypes.element,
  rootClassName: PropTypes.string,
  logoAlt: PropTypes.string,
}

export default Footer4
