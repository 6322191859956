import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './features25.css'

const Features25 = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className={`thq-section-padding ${props.rootClassName} `}>
      <div className="features25-container2 thq-section-max-width">
        <div className="features25-container3">
          <div className="features25-content1">
            <h2 className="thq-heading-2">
              {props.feature2Title ?? (
                <Fragment>
                  <span className="features25-text3">Leaderboard</span>
                </Fragment>
              )}
            </h2>
            <span className="thq-body-small padding-bottom">
              {props.feature2Description ?? (
                <Fragment>
                  <span className="features25-text5">
                    See who got spanked the most and climb to the top of the
                    leaderboard.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features25-content2">
            <h2 className="thq-heading-2">
              {props.feature3Title ?? (
                <Fragment>
                  <span className="features25-text2">Customize Spanking</span>
                </Fragment>
              )}
            </h2>
            <span className="thq-body-small">
              {props.feature3Description ?? (
                <Fragment>
                  <span className="features25-text4">
                    Personalize your spanking experience with different spanking
                    tools and effects.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features25-content3">
            <h2 className="thq-heading-2">
              {props.feature3Title2 ?? (
                <Fragment>
                  <span className="features25-text6">Customize Spanking</span>
                </Fragment>
              )}
            </h2>
            <span className="thq-body-small">
              {props.feature3Description2 ?? (
                <Fragment>
                  <span className="features25-text1">
                    Personalize your spanking experience with different spanking
                    tools and effects.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
        <div className="features25-image-container">
          {activeTab === 0 && (
            <img
              alt={props.feature1ImgAlt}
              src={props.feature1ImgSrc}
              className="features25-image1 thq-img-ratio-16-9 thq-img-ratio-1-1"
            />
          )}
          {activeTab === 1 && (
            <img
              alt={props.feature2ImgAlt}
              src={props.feature2ImgSrc}
              className="features25-image2 thq-img-ratio-16-9"
            />
          )}
          {activeTab === 2 && (
            <img
              alt={props.feature3ImgAlt}
              src={props.feature3ImgSrc}
              className="features25-image3 thq-img-ratio-16-9"
            />
          )}
        </div>
      </div>
    </div>
  )
}

Features25.defaultProps = {
  rootClassName: '',
  feature3ImgSrc:
    'https://images.unsplash.com/photo-1604893080692-2a656f2a8875?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODU5NTgzNnw&ixlib=rb-4.0.3&q=80&w=1080',
  feature3Description2: undefined,
  feature3Title: undefined,
  feature2Title: undefined,
  feature3ImgAlt: 'Customize spanking icon',
  feature1ImgSrc:
    'https://images.unsplash.com/photo-1653357297186-8a9e90ac29bc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODU5NTgzNXw&ixlib=rb-4.0.3&q=80&w=1400',
  feature3Description: undefined,
  feature2Description: undefined,
  feature2ImgSrc: 'https://play.teleporthq.io/static/svg/default-img.svg',
  feature3Title2: undefined,
  feature2ImgAlt: 'Leaderboard icon',
  feature1ImgAlt: 'Spanking hand icon',
}

Features25.propTypes = {
  rootClassName: PropTypes.string,
  feature3ImgSrc: PropTypes.string,
  feature3Description2: PropTypes.element,
  feature3Title: PropTypes.element,
  feature2Title: PropTypes.element,
  feature3ImgAlt: PropTypes.string,
  feature1ImgSrc: PropTypes.string,
  feature3Description: PropTypes.element,
  feature2Description: PropTypes.element,
  feature2ImgSrc: PropTypes.string,
  feature3Title2: PropTypes.element,
  feature2ImgAlt: PropTypes.string,
  feature1ImgAlt: PropTypes.string,
}

export default Features25
