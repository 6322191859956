import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import './spank-game.css'

const SpankGame = (props) => {
  return (
    <div className="spank-game-container1">
      <Helmet>
        <title>Spank-Game - Spank That Bitch</title>
        <meta
          name="description"
          content="Epic memecoin on Sui meets hilarious spanking game. Come join the fun!"
        />
        <meta property="og:title" content="Spank-Game - Spank That Bitch" />
        <meta
          property="og:description"
          content="Epic memecoin on Sui meets hilarious spanking game. Come join the fun!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/a8e89133-b2e4-445e-b52a-08f17bc9060b/3ae5a18d-aed4-4a8a-be8e-ae3d150b9afc?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="spank-game-text10">Spank a Bitch</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="spank-game-text11">
              <span>
                Buy
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="spank-game-text13">$</span>
              <span className="spank-game-text14">Spank</span>
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="spank-game-text15">/leaderboard</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="spank-game-text16">/about</span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="spank-game-text17">Home</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="spank-game-text18">Spank Politicians</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="spank-game-text19">Leaderboard</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="spank-game-text20">About</span>
          </Fragment>
        }
        link22={
          <Fragment>
            <span className="spank-game-text21">Buy $SPANK</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="spank-game-text22">Spank Now!</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="spank-game-text23">
              <span>
                Buy
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="spank-game-text25">$SPANK</span>
            </span>
          </Fragment>
        }
        logoSrc="/spankthatbitchlogo-1500h.webp"
        link2Url="https://dex.bluemove.net/token/0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK/0x2::sui::SUI"
        link2Url2="https://dex.bluemove.net/token/0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK/0x2::sui::SUI"
        page1Description={
          <Fragment>
            <span className="spank-game-text26">
              Spank That Bitch Home Page
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="spank-game-text27">
              Spank Politicians Virtually!
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="spank-game-text28">
              Check out who got spanked the most!
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="spank-game-text29">
              Learn more about Spank That Bitch
            </span>
          </Fragment>
        }
      ></Navbar8>
      <div className="spank-game-container2">
        <div className="spank-game-container3">
          <div className="spank-game-container4">
            <Script
              html={`<script>
  document.addEventListener("DOMContentLoaded", function() {
    // Get the current URL's search params
    const urlParams = new URLSearchParams(window.location.search);
    
    // Get the value of the 'bitch' parameter
    const param = urlParams.get('bitch');
    
    if (param) {
      // Find the iframe on the page (replace '#myIframe' with your iframe's ID or class)
      const iframe = document.querySelector('#myIframe');

      if (iframe) {
        // Get the current src of the iframe
        const iframeSrc = iframe.getAttribute('src');

        // Append the 'bitch' parameter to the iframe's src
        const newIframeSrc = iframeSrc.includes('?') 
          ? \`\${iframeSrc}&bitch=\${param}\` 
          : \`\${iframeSrc}?bitch=\${param}\`;

        // Set the new src for the iframe
        iframe.setAttribute('src', newIframeSrc);
      }
    }
  });
</script>

<iframe id="myIframe" src="https://spank-a-bitch-dev.web.app/spank" frameborder="0" style="width:100%;height:100%"></iframe>
`}
            ></Script>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpankGame
