import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './cta22.css'

const CTA22 = (props) => {
  return (
    <div
      id={props.cTA22Id}
      className={`cta22-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="cta22-max-width thq-section-max-width">
        <div className="cta22-row1">
          <div className="cta22-content">
            <h2 className="cta22-heading1 thq-heading-2">
              {props.heading1 ?? (
                <Fragment>
                  <span className="cta22-text2">Ready to Spank?</span>
                </Fragment>
              )}
            </h2>
            <span className="cta22-text1">
              {props.text ?? (
                <Fragment>
                  <span className="cta22-text3">
                    Check out our leaderboard for up to date spanks
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
        <div className="cta22-row2">
          <iframe
            src="https://spank-a-bitch-dev.web.app/leaderboard"
            className="cta22-iframe"
          ></iframe>
        </div>
      </div>
    </div>
  )
}

CTA22.defaultProps = {
  heading1: undefined,
  cTA22Id: '',
  rootClassName: '',
  text: undefined,
}

CTA22.propTypes = {
  heading1: PropTypes.element,
  cTA22Id: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.element,
}

export default CTA22
