import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './features24.css'

const Features24 = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div
      id={props.features24Id}
      className={`features24-container1 thq-section-padding ${props.rootClassName} `}
    >
      <div className="features24-container2 thq-section-max-width">
        <iframe
          src="https://spank-a-bitch-dev.web.app/spank"
          className="features24-iframe"
        ></iframe>
        <div className="features24-container3">
          <div className="features24-content1">
            <h2 className="thq-heading-2">
              {props.feature1Title ?? (
                <Fragment>
                  <span className="features24-text4">Virtual Spanking</span>
                </Fragment>
              )}
            </h2>
            <span className="thq-body-small">
              {props.feature1Description ?? (
                <Fragment>
                  <span className="features24-text1">
                    Spank politicians virtually and see who got spanked the
                    most!
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features24-content2">
            <h2 className="thq-heading-2">
              {props.feature2Title ?? (
                <Fragment>
                  <span className="features24-text2">Leaderboard</span>
                </Fragment>
              )}
            </h2>
            <span className="thq-body-small">
              {props.feature2Description ?? (
                <Fragment>
                  <span className="features24-text5">
                    Check the leaderboard to see who&apos;s been spanked the
                    most and climb to the top!
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features24-content3">
            <h2 className="thq-heading-2">
              {props.feature3Title ?? (
                <Fragment>
                  <span className="features24-text6">
                    Interactive Experience
                  </span>
                </Fragment>
              )}
            </h2>
            <span className="thq-body-small">
              {props.feature3Description ?? (
                <Fragment>
                  <span className="features24-text3">
                    Enjoy a fun and interactive experience while spanking those
                    in power!
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Features24.defaultProps = {
  feature1Description: undefined,
  features24Id: '',
  rootClassName: '',
  feature2Title: undefined,
  feature3Description: undefined,
  feature1Title: undefined,
  feature2Description: undefined,
  feature3Title: undefined,
}

Features24.propTypes = {
  feature1Description: PropTypes.element,
  features24Id: PropTypes.string,
  rootClassName: PropTypes.string,
  feature2Title: PropTypes.element,
  feature3Description: PropTypes.element,
  feature1Title: PropTypes.element,
  feature2Description: PropTypes.element,
  feature3Title: PropTypes.element,
}

export default Features24
