import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './steps2.css'

const Steps2 = (props) => {
  return (
    <div className="steps2-container1 thq-section-padding">
      <div className="steps2-max-width thq-section-max-width">
        <div className="steps2-container2 thq-grid-2">
          <div className="steps2-section-header">
            <h2 className="steps2-text10 thq-heading-2">
              <span className="steps2-text11">How to get</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="steps2-text13">$SPANK</span>
            </h2>
            <p className="thq-body-large">
              <span className="steps2-text15">$SPANK</span>
              <span className="steps2-text16">
                {' '}
                is deployed on the Sui blockchain. Move those Solana degen funds
                over there, anon!
              </span>
            </p>
            <div className="steps2-actions">
              <a
                href="https://dex.bluemove.net/token/0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK/0x2::sui::SUI"
                target="_blank"
                rel="noreferrer noopener"
                className="steps2-button thq-button-animated thq-button-filled"
              >
                <span className="thq-body-small">Buy $SPANK</span>
              </a>
            </div>
          </div>
          <div className="steps2-container3">
            <div className="steps2-container4 thq-card">
              <h2 className="steps2-text18 thq-heading-2">
                {props.step1Title ?? (
                  <Fragment>
                    <span className="steps2-text37">Download Wallet</span>
                  </Fragment>
                )}
              </h2>
              <span className="steps2-text19 thq-body-small">
                {props.step1Description ?? (
                  <Fragment>
                    <span className="steps2-text34">
                      <span>
                        Download a crypto wallet for Sui. We like to use
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <a
                        href="https://suiet.app"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="steps2-link1"
                      >
                        Suiet.
                      </a>
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text20 thq-heading-3">01</label>
            </div>
            <div className="steps2-container5 thq-card">
              <h2 className="steps2-text21 thq-heading-2">
                {props.step2Title ?? (
                  <Fragment>
                    <span className="steps2-text36">Get Gas</span>
                  </Fragment>
                )}
              </h2>
              <span className="thq-body-small">
                {props.step2Description ?? (
                  <Fragment>
                    <span className="steps2-text47">
                      Sui uses $SUI as the gas token. Get it from an exchange
                      like binance or kucoin. Send it to your wallet.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text23 thq-heading-3">02</label>
            </div>
            <div className="steps2-container6 thq-card">
              <h2 className="steps2-text24 thq-heading-2">
                {props.step3Title ?? (
                  <Fragment>
                    <span className="steps2-text43">
                      <span className="steps2-text44">Buy</span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="steps2-text46">$SPANK</span>
                    </span>
                  </Fragment>
                )}
              </h2>
              <span className="steps2-text25 thq-body-small">
                {props.step3Description ?? (
                  <Fragment>
                    <span className="steps2-text38">
                      <span>
                        Buy
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="steps2-text40">$SPANK</span>
                      <span>
                        {' '}
                        on
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <a
                        href="https://dex.bluemove.net/token/0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK/0x2::sui::SUI"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="steps2-link2"
                      >
                        bluemove
                      </a>
                      <span>.</span>
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-label thq-heading-3">03</label>
            </div>
            <div className="steps2-container7 thq-card">
              <h2 className="steps2-text26 thq-heading-2">
                {props.step4Title ?? (
                  <Fragment>
                    <span className="steps2-text30">
                      <span>
                        Don&apos;t forget to
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="steps2-text32">$SPANK</span>
                      <span> some bitches</span>
                    </span>
                  </Fragment>
                )}
              </h2>
              <span className="steps2-text27 thq-body-small">
                {props.step4Description ?? (
                  <Fragment>
                    <span className="steps2-text29">
                      Yeah, I dont really care if you buy the coin or not. Just
                      get to SPANKING these hoes.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text28 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps2.defaultProps = {
  step4Description: undefined,
  step4Title: undefined,
  step1Description: undefined,
  step2Title: undefined,
  step1Title: undefined,
  step3Description: undefined,
  step3Title: undefined,
  step2Description: undefined,
}

Steps2.propTypes = {
  step4Description: PropTypes.element,
  step4Title: PropTypes.element,
  step1Description: PropTypes.element,
  step2Title: PropTypes.element,
  step1Title: PropTypes.element,
  step3Description: PropTypes.element,
  step3Title: PropTypes.element,
  step2Description: PropTypes.element,
}

export default Steps2
