import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Hero17 from '../components/hero17'
import Features24 from '../components/features24'
import CTA22 from '../components/cta22'
import Banner1 from '../components/banner1'
import Features25 from '../components/features25'
import Steps2 from '../components/steps2'
import Contact10 from '../components/contact10'
import Footer4 from '../components/footer4'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>Spank That Bitch</title>
        <meta
          name="description"
          content="Epic memecoin on Sui meets hilarious spanking game. Come join the fun!"
        />
        <meta property="og:title" content="Spank That Bitch" />
        <meta
          property="og:description"
          content="Epic memecoin on Sui meets hilarious spanking game. Come join the fun!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/a8e89133-b2e4-445e-b52a-08f17bc9060b/3ae5a18d-aed4-4a8a-be8e-ae3d150b9afc?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="home-text10">Start spankin&apos;</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text11">Leaderboards</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text12">
              https://play.teleporthq.io/static/svg/placeholders/no-image.svg
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text13">
              https://play.teleporthq.io/static/svg/placeholders/no-image.svg
            </span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="home-text14">
              https://play.teleporthq.io/static/svg/placeholders/no-image.svg
            </span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="home-text15">
              https://play.teleporthq.io/static/svg/placeholders/no-image.svg
            </span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="home-text16">
              https://play.teleporthq.io/static/svg/placeholders/no-image.svg
            </span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="home-text17">
              https://play.teleporthq.io/static/svg/placeholders/no-image.svg
            </span>
          </Fragment>
        }
        link22={
          <Fragment>
            <span className="home-text18">Buy $SPANK</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text19">
              <span>
                Buy
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text21">$SPANK</span>
            </span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text22">Spank Now!</span>
          </Fragment>
        }
        logoSrc="/spankthatbitchlogo-1500h.webp"
        link1Url="/spank"
        link2Url="#CTA22"
        link2Url1="https://movepump.com/token/0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK"
        link2Url2="https://dex.bluemove.net/token/0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK/0x2::sui::SUI"
        page1Description={
          <Fragment>
            <span className="home-text23">
              https://play.teleporthq.io/static/svg/placeholders/no-image.svg
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="home-text24">
              https://play.teleporthq.io/static/svg/placeholders/no-image.svg
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="home-text25">
              https://play.teleporthq.io/static/svg/placeholders/no-image.svg
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="home-text26">
              https://play.teleporthq.io/static/svg/placeholders/no-image.svg
            </span>
          </Fragment>
        }
      ></Navbar8>
      <Hero17
        action1={
          <Fragment>
            <span className="home-text27">
              <span>
                Buy
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text29">$SPANK</span>
            </span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text30">Spank Now!</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text31">
              <span className="home-text32">
                Our leaders have been very naughty. They need to be punished
              </span>
              <span className="home-text33">
                . Lets see who gets
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text34">SPANKED</span>
              <span className="home-text35"> the most.</span>
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text36">
              <span className="home-text37">$SPANK</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text39">THAT BITCH</span>
            </span>
          </Fragment>
        }
        image1Src="/obamamichelle%20(1)-1500w.png"
        image2Src="/kamalaimmigrant-1500w.png"
        image3Src="/trumpboom-1500w.png"
        image4Src="/trumpbidengolfing-1500w.png"
        image5Src="/bidenboom-1500w.png"
        image6Src="/hillarysmacked-1500w.png"
        image7Src="/pelosismacked-1500w.png"
        image8Src="/peolositrolling-1500w.png"
        image9Src="/bushnbushtroll-1500w.png"
        link2Url2="https://dex.bluemove.net/swap/0x2::sui::SUI/0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK"
        image10Src="/obamasmacked-1500w.png"
        image11Src="/trudeausmacked-1500w.png"
        image12Src="/mask%20group%20(9)-1500w.png"
        rootClassName="hero17root-class-name"
      ></Hero17>
      <Features24
        features24Id="Features24"
        feature1Title={
          <Fragment>
            <span className="home-text40">
              <span className="home-text41">
                Click to
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text42">$SPANK</span>
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text43">Leaderboard</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text44">Buy the Coin</span>
          </Fragment>
        }
        rootClassName="features24root-class-name"
        feature1Description={
          <Fragment>
            <span className="home-text45">
              Click on the bitch to spank his ass!
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text46">
              Check the leaderboard to see who&apos;s been spanked the most!
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text47">
              <span className="home-text48">
                Buy the coin
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <a
                href="https://dex.bluemove.net/swap/0x2::sui::SUI/0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link1"
              >
                $SP
              </a>
              <span className="home-text49">ANK</span>
              <span>,</span>
              <span className="home-text51"> don&apos;t be a bitch!</span>
            </span>
          </Fragment>
        }
      ></Features24>
      <CTA22
        text={
          <Fragment>
            <span className="home-text52">
              <span>
                Check out our leaderboard for up to date
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text54">$</span>
              <span className="home-text55">SPANKS</span>
            </span>
          </Fragment>
        }
        cTA22Id="CTA22"
        heading1={
          <Fragment>
            <span className="home-text56">Who&apos;s the Biggest Bitch?</span>
          </Fragment>
        }
        rootClassName="cta22root-class-name"
      ></CTA22>
      <Banner1
        heading={
          <Fragment>
            <span className="home-text57">
              0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text58">Join the fun </span>
          </Fragment>
        }
      ></Banner1>
      <Features25
        feature2Title={
          <Fragment>
            <span className="home-text59">✅ LP Burnt</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text60">✅ No Team Allocation</span>
          </Fragment>
        }
        rootClassName="features25root-class-name"
        feature1ImgSrc="/trumpbidengolfing-1500w.png"
        feature3Title2={
          <Fragment>
            <span className="home-text61">✅ No Tax</span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text62">No rugs here. </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="home-text63">
              It&apos;s a fair launch motherfucker.
            </span>
          </Fragment>
        }
        feature3Description2={
          <Fragment>
            <span className="home-text64">No bullshit.</span>
          </Fragment>
        }
      ></Features25>
      <Steps2
        step1Title={
          <Fragment>
            <span className="home-text65">Download Wallet</span>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <span className="home-text66">Get Gas</span>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <span className="home-text67">
              <span className="home-text68">Buy</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text70">$SPANK</span>
            </span>
          </Fragment>
        }
        step4Title={
          <Fragment>
            <span className="home-text71">
              <span>
                Don&apos;t forget to
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text73">$SPANK</span>
              <span> some bitches</span>
            </span>
          </Fragment>
        }
        step1Description={
          <Fragment>
            <span className="home-text75">
              <span>
                Download a crypto wallet for Sui. We like to use
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <a
                href="https://suiet.app"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link2"
              >
                Suiet.
              </a>
            </span>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text77">
              Sui uses $SUI as the gas token. Get it from an exchange like
              binance or kucoin. Send it to your wallet.
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text78">
              <span>
                Buy
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text80">$SPANK</span>
              <span>
                {' '}
                on
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <a
                href="https://dex.bluemove.net/swap/0x2::sui::SUI/0x193a5fa8050fbb156dc4992f6a7a7d288a54a5f464bbd5f9ef33db6840d4c941::spank::SPANK"
                target="_blank"
                rel="noreferrer noopener"
              >
                blue move
              </a>
            </span>
          </Fragment>
        }
        step4Description={
          <Fragment>
            <span className="home-text82">
              Yeah, I dont really care if you buy the coin or not. Just get to
              SPANKING these hoes.
            </span>
          </Fragment>
        }
      ></Steps2>
      <Contact10
        content1={
          <Fragment>
            <span className="home-text83">Let&apos;s just fuck around.</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text84">What are you waiting for?</span>
          </Fragment>
        }
        location1={
          <Fragment>
            <span className="home-text85">He&apos;s a chad</span>
          </Fragment>
        }
        location2={
          <Fragment>
            <span className="home-text86">Kamala needs a spanking</span>
          </Fragment>
        }
        rootClassName="contact10root-class-name"
        location1ImageSrc="/leonardo_phoenix_a_vibrant_exaggerated_cartoon_depiction_of_pr_0%20(1)-1400w.jpg"
        location2ImageSrc="/kamalaimmigrant-1500w.png"
      ></Contact10>
      <div className="home-container2">
        <div className="home-content">
          <div className="home-row-container1 thq-mask-image-horizontal thq-animated-group-container-horizontal">
            <div className="thq-animated-group-horizontal">
              <img
                alt="Hero Image"
                src="/obamamichelle%20(1)-1500w.png"
                className="home-placeholder-image10 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="/kamalaimmigrant-1500w.png"
                className="home-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="/trumpboom-1500w.png"
                className="home-placeholder-image12 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="/trumpbidengolfing-1500w.png"
                className="home-placeholder-image13 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="/bidenboom-1500w.png"
                className="home-placeholder-image14 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="/hillarysmacked-1500w.png"
                className="home-placeholder-image15 thq-img-scale thq-img-ratio-1-1"
              />
            </div>
            <div className="thq-animated-group-horizontal">
              <img
                alt="Hero Image"
                src="https://images.unsplash.com/photo-1580128637411-80206ae868e5?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODU5NTgzOHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
                className="home-placeholder-image16 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                className="home-placeholder-image17 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="https://images.unsplash.com/photo-1541872703-74c5e44368f9?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODU5NTgzNHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
                className="home-placeholder-image18 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="https://images.unsplash.com/photo-1634588529785-59b3a9c1e4e3?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODU5NTgzOHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
                className="home-placeholder-image19 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="https://images.unsplash.com/photo-1660760610294-924c341eafd9?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODU5NTgzN3w&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
                className="home-placeholder-image20 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="https://images.unsplash.com/photo-1534312527009-56c7016453e6?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
                className="home-placeholder-image21 thq-img-scale thq-img-ratio-1-1"
              />
            </div>
          </div>
          <div className="home-row-container2 thq-mask-image-horizontal thq-animated-group-container-horizontal">
            <div className="thq-animated-group-horizontal-reverse">
              <img
                alt="Hero Image"
                src="/pelosismacked-1500w.png"
                className="home-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="/peolositrolling-1500w.png"
                className="home-placeholder-image23 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="/bushnbushtroll-1500w.png"
                className="home-placeholder-image24 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="/obamasmacked-1500w.png"
                className="home-placeholder-image25 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="/trudeausmacked-1500w.png"
                className="home-placeholder-image26 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="/mask%20group%20(9)-1500w.png"
                className="home-placeholder-image27 thq-img-scale thq-img-ratio-1-1"
              />
            </div>
            <div className="thq-animated-group-horizontal-reverse">
              <img
                alt="Hero Image"
                src="https://images.unsplash.com/photo-1660581284151-d689ed3e99aa?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODU5NTgzNXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
                className="home-placeholder-image28 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                className="home-placeholder-image29 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="https://images.unsplash.com/photo-1616984473937-ff1ade8340ea?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODU5NTgzNnw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
                className="home-placeholder-image30 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="https://images.unsplash.com/photo-1499938971550-7ad287075e0d?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODU5NTgzOHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
                className="home-placeholder-image31 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="https://images.unsplash.com/photo-1658337922327-c93af0f1917e?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODU5NTgzNHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=1080"
                className="home-placeholder-image32 thq-img-scale thq-img-ratio-1-1"
              />
              <img
                alt="Hero Image"
                src="https://images.unsplash.com/photo-1568214379698-8aeb8c6c6ac8?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxncmFmaWN8ZW58MHx8fHwxNzE1Nzk0OTk5fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
                className="home-placeholder-image33 thq-img-scale thq-img-ratio-1-1"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="home-container4">
            <Script
              html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
            ></Script>
          </div>
        </div>
      </div>
      <Footer4
        link1={
          <Fragment>
            <span className="home-text87">
              <span className="home-text88">Buy</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text90">$SPANK</span>
            </span>
          </Fragment>
        }
        link11={
          <Fragment>
            <span className="home-text91">Chart</span>
          </Fragment>
        }
        link12={
          <Fragment>
            <span className="home-text92">Contact Us</span>
          </Fragment>
        }
        logoSrc="/spankthatbitchlogo-1500h.webp"
        rootClassName="footer4root-class-name"
      ></Footer4>
    </div>
  )
}

export default Home
